import React from "react";
import "twin.macro";
import { graphql, Link } from "gatsby";
import { ContactForm, CTA, PageLayout } from "../components";
import slugify from "slugify";

const ContactUs = ({
  data: {
    allStrapiBranchSettings: { edges },
  },
}) => {
  const branches = edges.map((a) => a.node);

  return (
    <PageLayout
      title="Contact Us"
      subtitle="Our team are always happy to help."
    >
      <section tw="bg-white border-b">
        <div tw="lg:px-8 lg:py-24 max-w-7xl mx-auto px-4 py-16 sm:px-6 text-dsrTeal-900">
          <div tw="divide-y-2 divide-gray-200">
            <div tw="lg:grid lg:grid-cols-3 lg:gap-8">
              <h2 tw="text-2xl font-extrabold text-gray-900 sm:text-3xl">
                Get in touch
              </h2>
              <div tw="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                {branches.map(
                  ({
                    name,
                    secondaryName,
                    contactDetails: { email, telephone },
                  }) => (
                    <div key={name}>
                      <Link
                        to={`/locations/${slugify(name.toLowerCase())}`}
                        key={name}
                      >
                        <h3 tw="text-lg leading-6 font-medium text-gray-900">
                          {name}
                        </h3>
                        {secondaryName && (
                          <h3 tw="text-sm leading-3 font-medium text-gray-700 mt-1">
                            {secondaryName}
                          </h3>
                        )}
                      </Link>
                      <dl tw="mt-2 text-base text-gray-500">
                        <div tw="mt-1">
                          <dt tw="sr-only">Phone number </dt>
                          <dd>{telephone}</dd>
                        </div>
                        <div>
                          <dt tw="sr-only">Email </dt>
                          <dd>
                            <a
                              href={`mailto:${email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {email}
                            </a>
                          </dd>
                        </div>
                      </dl>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div tw="bg-white border-b relative">
          <div tw="absolute inset-0">
            <div tw="absolute bg-dsrTeal-50 inset-y-0 left-0 w-1/2"></div>
          </div>
          <div tw="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
            <div tw="bg-dsrTeal-50 px-4 py-16 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
              <div tw="max-w-lg mx-auto">
                <h2 tw="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-3xl">
                  Send us a Message
                </h2>
                <p tw="mt-3 text-lg leading-6 text-gray-500">
                  Select your branch and submit the form to send us a message
                  via email.
                </p>
                <p tw="mt-6 text-base text-gray-500">
                  Looking for jobs?{" "}
                  <Link to="/careers" tw="font-medium text-gray-700 underline">
                    View all job openings.
                  </Link>
                </p>
              </div>
            </div>
            <div tw="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
              <div tw="max-w-lg mx-auto lg:max-w-none">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTA />
    </PageLayout>
  );
};

export default ContactUs;

export const query = graphql`
  query contactUsQuery {
    allStrapiBranchSettings(filter: { group: { name: { eq: "DSR" } } }) {
      edges {
        node {
          name
          secondaryName
          contactDetails {
            email
            telephone
          }
        }
      }
    }
  }
`;
